import "react-tooltip/dist/react-tooltip.css";
import "../styles/globals.css";

import { useEffect, useState } from "react";
import { Provider } from "jotai";
import { useScrollRestoration } from "next-restore-scroll-position";
import App from "next/app";
import Head from "next/head";
import { Toaster } from "react-hot-toast";
import { register } from "swiper/element/bundle";

import { checkUserSubscription, getUserIpAddress } from "@/services/api/User";

import AppContext from "../AppContext";
import { getContentTypes, getTopics } from "../services/api";
// import { IntercomProvider } from "../utils/intercomProvider";

import { ReCaptchaProvider } from "next-recaptcha-v3";
import useAuth from "@/hooks/auth";
import axios from "axios";
import { getWishlist, deleteWishlist, getWishlistCategory, createWishlist, createWishlistCategory } from "@/services/api/Wishlist";

register();

function MyApp({ Component, pageProps, router, content_types, topics }) {
  useScrollRestoration(router);

  const [contentTypes, setContentTypes] = useState([]);
  const [topic_list, setTopics] = useState([]);
  const [planSubscription, setPlanSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [userWishlist, setUserWishlist] = useState([])
  const [userStartupWishlistIds, setUserStartupWishlistIds] = useState([])
  const [userVCWishlistIds, setUserVCWishlistIds] = useState([])
  const [userContentWishlistIds, setUserContentWishlistIds] = useState([])
  const [wishlistCategories, setWishlistCategories] = useState(null);

  const getUserSubscription = async () => {
    setLoading(true);
    try {
      const response = await checkUserSubscription();
      // if (response.data.has_active_subscription) {
      setPlanSubscription(response.data);
      // }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getUserSubscription();
    getUserWishlist();
  }, []);

  const addWishlistCategory = async (payload) => {
    try {
      const res = await createWishlistCategory(payload);
      setWishlist(res.data.id, payload.id, payload.type, payload.content_type_name);
      setWishlistCategories(null);
      getWishlistCategories();
    } catch (error) {
      console.log(error);
    }
  };

  const getWishlistCategories = async () => {
    if (wishlistCategories === null) {
      const res = await getWishlistCategory();
      setWishlistCategories(res.data);
    }
  };

  const setWishlist = async (cat_id, id, type, content_type_name) => {
    const res = await createWishlist({
      category_id: cat_id,
      content_id: id,
      type: type,
      content_type_name: content_type_name
    });
    getUserWishlist();
  };
  
  const getUserWishlist = async () => {
    const res = await getWishlist({
      per_page: 1000,
    });
    setUserWishlist(res.data.data);
    setUserStartupWishlistIds(res.data.data.filter(v => v.type == 1).map((v) => v.content_id));
    setUserVCWishlistIds(res.data.data.filter(v => v.type == 2).map((v) => v.content_id));
    setUserContentWishlistIds(res.data.data.filter(v => v.type == 0).map((v) => v.content_id));
  };

  const removeWishlist = async (id) => {
    const res = await deleteWishlist(id);
    getUserWishlist();
  };

  const onRemoveStartupWishlist = (e, startup_id) => {
    e.stopPropagation();
    e.preventDefault();
    const found = userWishlist.find((v) => v.type === 1 && v.content_id == startup_id);
    if (found) {
      removeWishlist(found.id);
    }
  };

  const onRemoveVCWishlist = (e, vc_id) => {
    e.stopPropagation();
    e.preventDefault();
    const found = userWishlist.find((v) => v.type === 2 && v.content_id == vc_id);
    if (found) {
      removeWishlist(found.id);
    }
  };

  const onRemoveContentWishlist = (e, content_id) => {
    e.stopPropagation();
    e.preventDefault();
    const found = userWishlist.find((v) => v.type === 0 && v.content_id == content_id);
    if (found) {
      removeWishlist(found.id);
    }
  };

  const getUserIp = async () => {
    const lctdIa = localStorage.getItem('lctd-ia');
    if (!lctdIa) {
      try {
        const response = await getUserIpAddress();
        if (response.data?.ip) {
          localStorage.setItem('lctd-ia', response.data.ip);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getUserIp()
    // Define the function to track page views
    const handleRouteChange = (url) => {
      const pageTitle = document.title;
      // Make sure Umami is defined and is not on the server
      if (
        typeof window.umami !== "undefined" &&
        typeof window.umami.track === "function"
      ) {
        window.umami.track((props) => ({
          ...props,
          url,
          title: pageTitle,
          name: "page_view",
          data: {
            url,
            domain: process.env.NEXT_PUBLIC_BASE_URL,
            label: "Page",
            email: user ? user.email : null,
            ip_: localStorage.getItem('lctd-ia'),
          },
        }));
      }
    };

    // Track the initial page load
    handleRouteChange(window.location.pathname);

    // Subscribe to route changes to track page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // Unsubscribe from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, user]);

  const getCanonicalUrl = () => {
    let path = router.asPath;
    if (path === "") {
      return process.env.BASE_URL;
    }

    if (path.charAt(0) === "/") {
      return `${process.env.BASE_URL}${path.substring(1)}`;
    }

    return `${process.env.BASE_URL}${path}`;
  };

  return (
    <Provider>
      <ReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      >
        {/* <IntercomProvider> */}
        <AppContext.Provider
          value={{
            state: {
              contentTypes: content_types || [],
              topic_list: topics,
              planSubscription: planSubscription,
              loading: loading,
              refreshUserSubscription: getUserSubscription,
            },
            setContentTypes,
            setTopics,
            setPlanSubscription,
            userStartupWishlistIds,
            userVCWishlistIds,
            userContentWishlistIds,
            onRemoveStartupWishlist,
            onRemoveVCWishlist,
            getWishlistCategories,
            wishlistCategories,
            setWishlist,
            addWishlistCategory,
            onRemoveContentWishlist,
            userVCWishlist: userWishlist.filter(w => w.type == 2),
            userStartupWishlist: userWishlist.filter(w => w.type == 1),
            userContentWishlist: userWishlist.filter(w => w.type == 0)
          }}
        >
          <Head>
            <link rel='canonical' href={getCanonicalUrl()} />
            <link rel='icon' href='/images/favicon.png' type='image/png' />
            {process.env.ENV !== "production" && (
              <meta name='robots' content='noindex' />
            )}
          </Head>

          <Toaster position='top-center' />
          <Component {...pageProps} />
        </AppContext.Provider>
        {/* </IntercomProvider> */}
      </ReCaptchaProvider>
    </Provider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  try {
    const responses = await Promise.all([getContentTypes(), getTopics()]);

    return {
      ...appProps,
      content_types: responses[0].data,
      topics: responses[1].data,
    };
  } catch (error) {
    console.log("Error A", error);
    return {
      ...appProps,
      content_types: [],
      topics: [],
    };
  }
};

export default MyApp;
