import emailList from "../official_email_list.json"
import { officialEmailService } from '../services/officialEmailService';

function checkEmailDomain(email, domain) {
    if (!email) return false
  let emailParts = email.split("@");

  if (emailParts.length !== 2) {
    return false;
  }

  let emailDomain = emailParts[1];

  let urlDomain;
  try {
    if (!/^http(s)?:\/\//.test(domain)) {
      domain = "https://" + domain;
    }
    urlDomain = new URL(domain).hostname;
  } catch (_) {
    return false;
  }

  // Get the base domain from the urlDomain
  let baseDomainParts = urlDomain.split(".").slice(-2);
  let baseDomain = baseDomainParts.join(".");

  return emailDomain === baseDomain;
}

export function getBaseDomain(domain) {
  let urlDomain;
  try {
    if (!/^http(s)?:\/\//.test(domain)) {
      domain = "https://" + domain;
    }
    urlDomain = new URL(domain).hostname;
  } catch (_) {
    return false;
  }

  // Get the base domain from the urlDomain
  let baseDomainParts = urlDomain.split(".").slice(-2);
  let baseDomain = baseDomainParts.join(".");

  return baseDomain;
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidDomain(domain) {
  const domainReg = /^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/?|$)/;
  return domainReg.test(domain);
}

async function findVerificationEmails(domain) {
  return await officialEmailService.getVerificationEmails(domain);
}

export async function checkEmailDomainWithList(email, domain) {
  if (!email || !domain) return false;
  
  let baseDomain = getBaseDomain(domain);
  if (!baseDomain) return false;

  // Get the verification emails from the officialEmailService
  let verificationEmails = await findVerificationEmails(baseDomain);
  verificationEmails.push(baseDomain)

  // Extract the domain from the provided email
  let emailDomain = email.split('@')[1];

  // Check if the email is in the list of verification emails
  return verificationEmails.includes(emailDomain);
}

export async function getBaseDomainWithList(domain) {
  if (!domain) return { error: 'No domain provided' };

  let urlDomain;
  try {
    if (!/^https?:\/\//i.test(domain)) {
      domain = "https://" + domain;
    }
    urlDomain = new URL(domain).hostname;
  } catch (error) {
    return { error: 'Invalid domain' };
  }

  // Get the base domain from the urlDomain
  const baseDomainParts = urlDomain.split('.');
  const baseDomain = baseDomainParts.slice(-2).join('.');

  try {
    // Find the verification email domains
    let verificationEmails = await findVerificationEmails(baseDomain);
    verificationEmails.push(baseDomain)

    // Return all verification emails and the base domain
    return {
      verificationEmails,
      baseDomain
    };
  } catch (error) {
    return { error: 'Failed to fetch verification emails', baseDomain };
  }
}

export default checkEmailDomain;
