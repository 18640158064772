import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";

export async function getWishlist(params) {
  return await httpUtils.get(`${env.API_VERSION}/wishlists`, { params });
}

export async function createWishlist(payload) {
  return await httpUtils.post(`${env.API_VERSION}/wishlists`, payload);
}

export async function moveWishlist(id, payload) {
  return await httpUtils.post(`${env.API_VERSION}/wishlists/${id}/move`, payload);
}

export async function deleteWishlist(id) {
  return await httpUtils.delete(`${env.API_VERSION}/wishlists/${id}`);
}

export async function getWishlistCategory(params) {
  return await httpUtils.get(`${env.API_VERSION}/wishlist-categories`, { params });
}

export async function createWishlistCategory(payload) {
  return await httpUtils.post(`${env.API_VERSION}/wishlist-categories`, payload);
}

export async function editWishlistCategory(id, payload) {
  return await httpUtils.put(`${env.API_VERSION}/wishlist-categories/${id}`, payload);
}

export async function deleteWishlistCategory(id) {
  return await httpUtils.delete(`${env.API_VERSION}/wishlist-categories/${id}`);
}