import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";

export const loginPopup = atom(false);
export const registerPopup = atom(false);
export const purchasePopup = atom("");
export const purchaseStartupPopup = atom(false);
export const purchaseVCPopup = atom(false);
export const subscribePopup = atom(false);
export const hidePurchasePopup = atom(false);
export const claimVCPopup = atom(null);
export const startupToClaimAtom = atom(null);
export const vcToClaimAtom = atom(null);
export const userAtom = atom(null);
export const audioAtom = atom(null);
export const userStartupWishlistIds = atom(null);

// Audio Player
export const currentTimeAtom = atom(0);
export const durationAtom = atom(0);
export const isPlayingAtom = atom(false);
