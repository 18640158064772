import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";

const BASE_URL = `${env.API_VERSION}/official-emails`;

export async function getVerificationEmail(baseDomain, config) {
    return httpUtils.get(`${BASE_URL}/verification-email/${baseDomain}`, config);
}

export async function verificationEmailExists(verificationEmailDomain, baseDomain, config) {
    return httpUtils.get(`${BASE_URL}/verification-email-exists/${verificationEmailDomain}/${baseDomain}`, config);
}

// Add other official email related API calls here if needed