import { getVerificationEmail, verificationEmailExists } from './api/OfficialEmail';

export const officialEmailService = {
  async getVerificationEmails(baseDomain) {
    try {
      const response = await getVerificationEmail(baseDomain);
      return response.data || []; // Return all verification emails
    } catch (error) {
      console.error('Error fetching verification emails:', error);
      return [];
    }
  },

  async verificationEmailExists(verificationEmailDomain, baseDomain) {
    try {
      const response = await verificationEmailExists(verificationEmailDomain, baseDomain);
      return response.data.exist;
    } catch (error) {
      console.error('Error checking verification email existence:', error);
      return false;
    }
  }
};