import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";
import { CONTENT_TYPES } from "@/utils/content";

export async function getContents(params) {
  return await httpUtils.get(`${env.API_VERSION}/content/list`, { params });
}

export async function getContentsByTopics(params) {
  return await httpUtils.get(`${env.API_VERSION}/content/list-by-topic`, { params });
}

export async function getTrendingContents() {
  return await httpUtils.get(`${env.API_VERSION}/content/trending`);
}

export async function getTopics() {
  return await httpUtils.get(`${env.API_VERSION}/topic`, {
    params: { all: true },
  });
}

export function getContentTypes() {
  return httpUtils.get(`${env.API_VERSION}/content-type`, {
    params: {
      per_page: 999,
      all: true,
    },
  });
}

export function getContentsByContentType(content_type, params) {
  return httpUtils.get(`${env.API_VERSION}/content/list`, {
    params: {
      ...(params || {}),
      contentTypeUuid: content_type,
    },
  });
}

export function getContentsByContentTypeName(content_type_name, params) {
  return httpUtils.get(`${env.API_VERSION}/content/list`, {
    params: {
      ...(params || {}),
      per_page: CONTENT_TYPES.SPOTLIGHTS ? 20 : 10,
      contentTypeName: content_type_name,
    },
  });
}

export function getContentsHomePage(params) {
  return httpUtils.get(`${env.API_VERSION}/content/list-home-page`, {
    params: {
      ...(params || {})
    },
  });
}

export function getContentsLatest(params) {
  return httpUtils.get(`${env.API_VERSION}/content/list-latest`, {
    params: {
      ...(params || {})
    },
  });
}

export function getContentsByTypeAndTopic(content_type, topic) {
  return httpUtils.get(`${env.API_VERSION}/content/list`, {
    params: {
      contentTypeUuid: content_type,
      topicUuid: topic,
    },
  });
}

export function getContentsByTypeNameAndTopic(content_type_name, topic) {
  return httpUtils.get(`${env.API_VERSION}/content/list`, {
    params: {
      contentTypeName: content_type_name,
      topicUuid: topic,
    },
  });
}

export function getContentsByTopic(topic, params) {
  return httpUtils.get(`${env.API_VERSION}/content/list`, {
    params: {
      topicUuid: topic,
      ...params,
    },
  });
}

export function getContentDetailBySlug(slug, params) {
  return httpUtils.get(`${env.API_VERSION}/content/detail/${slug}`, params);
}

export function getTopicDetail(slug) {
  return httpUtils.get(`${env.API_VERSION}/topic/${slug}`);
}

export function getConnectedContents(content_type, uuid) {
  return httpUtils.get(`${env.API_VERSION}/content/connected-list/${uuid}`, {
    params: {
      contentTypeUuid: content_type,
      all: true,
    },
  });
}

export function getConnectedContentsByName(content_type_name, uuid) {
  return httpUtils.get(`${env.API_VERSION}/content/connected-list/${uuid}`, {
    params: {
      contentTypeName: content_type_name,
      all: true,
    },
  });
}

export function getRelatedContent(
  content_type,
  uuid,
  per_page = null,
  is_random = false
) {
  return httpUtils.get(`${env.API_VERSION}/content/connected-content/${uuid}`, {
    params: {
      contentTypeUuid: content_type,
      all: per_page === null ? 1 : 0,
      per_page,
      is_random,
    },
  });
}

export function getRelatedContentName(
  content_type_name,
  uuid,
  per_page = null,
  is_random = false
) {
  return httpUtils.get(`${env.API_VERSION}/content/connected-content/${uuid}`, {
    params: {
      contentTypeName: content_type_name,
      all: per_page === null ? 1 : 0,
      per_page,
      is_random,
    },
  });
}

export function getRelatedContentNameByEntity(
  content_type_name,
  per_page = null,
  is_random = false,
  entity // startup, venture-capital
) {
  const params = {
    contentTypeName: content_type_name,
    all: per_page === null ? 1 : 0,
    per_page,
    is_random,
  }

  if (entity.type === 'startup') {
    params.startup_id = entity.id
  } else if (entity.type === 'venture-capital') {
    params.investor_id = entity.id
  }

  return httpUtils.get(`${env.API_VERSION}/content/related-content-by-entity`, {
    params,
  });
}

export function getLatestArticles(article_uuid) {
  return getContentsByContentType(article_uuid);
}

export function downloadReportFile(reportUuid) {
  return httpUtils.post(`${env.API_VERSION}/report/${reportUuid}/download`);
}

export function directDownloadReportFile(reportUuid) {
  return httpUtils.post(
    `${env.API_VERSION}/report/${reportUuid}/direct-download`
  );
}

export function searchContents(params) {
  return httpUtils.get(`${env.API_VERSION}/search`, { params });
}

export function readReport(uuid) {
  return httpUtils.post(`${env.API_VERSION}/report/${uuid}/read`);
}

export function downloadReportWithForm(uuid, payload) {
  return httpUtils.post(
    `${env.API_VERSION}/report/${uuid}/with-form-download`,
    payload
  );
}

export function trackContentAccessCounting(slug, payload) {
  return httpUtils.post(`${env.API_VERSION}/increment-count/${slug}`, payload);
}

export function getHighlightedContent(params) {
  return httpUtils.get(`${env.API_VERSION}/content/highlighted`, { params });
}

export function getBannerContents(params) {
  return httpUtils.get(`${env.API_VERSION}/banners`, {
    params: {
      ...(params || {})
    },
  });
}

export function saveDownloadForm(payload) {
  return httpUtils.post(`${env.API_VERSION}/download-form`, payload);
}

export function getPinnedByContentId(contentId) {
  return httpUtils.get(`${env.API_VERSION}/content/pinned/${contentId}`);
}

export function getRelatedInfobytes(contentId) {
  return httpUtils.get(`${env.API_VERSION}/content/related-infobytes/${contentId}`);
}

export function likeContent(contentId) {
  return httpUtils.post(`${env.API_VERSION}/content/likes/${contentId}`);
}

export function unlikeContent(contentId) {
  return httpUtils.delete(`${env.API_VERSION}/content/unlikes/${contentId}`);
}

export function userLike(contentId) {
  return httpUtils.get(`${env.API_VERSION}/content/user-like/${contentId}`);
}

export async function mostViewedContentByContentTypeName(params) {
  return httpUtils.get(`${env.API_VERSION}/content/most-viewed/by-content-type-name`, { params });
}

export async function newestContentByContentTypeName(params) {
  return httpUtils.get(`${env.API_VERSION}/content/newest/by-content-type-name`, { params });
}