import axios from "axios";
import { getCookie } from 'cookies-next';

let headers = {
  "X-Requested-With": "XMLHttpRequest"
}

if (process.env.ENV == 'local') {
  headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Authorization": `Bearer ${getCookie('dev-token')}`
  }
}

const defaultAxiosOptions = {
  baseURL: process.env.API_BASE_URL,
  headers,
  withCredentials: true,
};

const instance = axios.create(defaultAxiosOptions);

// Function to update headers
export const updateHeaders = (newHeaders) => {
  headers = {
    ...headers,
    ...newHeaders
  };
  instance.defaults.headers = headers;
};

export default instance;
